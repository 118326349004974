import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizeGuard } from '~ng-shell';

import { ShellComponent } from './components/shell/shell.component';

const generateDefaultChild = (key: string, appUrl: string) => [
  { path: '**', component: ShellComponent, data: { appUrl, key } },
];

export const routes: Routes = [
  {
    path: 'welcome',
    component: ShellComponent,
    data: { key: 'welcome', appUrl: 'welcome' },
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'profile',
    component: ShellComponent,
    data: { appUrl: 'profile', key: 'profile' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('profile', 'profile'),
  },

  // -- Assistance
  {
    path: 'storage',
    component: ShellComponent,
    data: { appUrl: 'storage', key: 'storage' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('storage', 'storage'),
  },
  {
    path: 'maintenance',
    component: ShellComponent,
    data: { appUrl: 'maintenance', key: 'maintenance' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('maintenance', 'maintenance'),
  },
  {
    path: 'time2lubrify',
    component: ShellComponent,
    data: { appUrl: 'time2lubrify', key: 'time2lubrify' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('time2lubrify', 'time2lubrify'),
  },
  {
    path: 'import',
    component: ShellComponent,
    data: { appUrl: 'import', key: 'import' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('import', 'import'),
  },

  // -- Analytics
  {
    path: 'performance',
    component: ShellComponent,
    data: { key: 'performance', appUrl: 'performance' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('performance', 'performance'),
  },
  {
    path: 'ebi-report',
    component: ShellComponent,
    data: { key: 'ebi-report', appUrl: 'ebi-report' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('ebi-report', 'ebi-report'),
  },
  {
    path: 'track-and-trace',
    component: ShellComponent,
    data: { appUrl: 'track-and-trace', key: 'track-and-trace' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('track-and-trace', 'track-and-trace'),
  },
  {
    path: 'integrated-analytics',
    component: ShellComponent,
    data: { appUrl: 'integrated-analytics', key: 'integrated-analytics' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('integrated-analytics', 'integrated-analytics'),
  },
  {
    path: 'energy-and-media-analytics',
    component: ShellComponent,
    data: { appUrl: 'energy-and-media-analytics', key: 'energy-and-media-analytics' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('energy-and-media-analytics', 'energy-and-media-analytics'),
  },
  {
    path: 'sap-hana',
    component: ShellComponent,
    data: { appUrl: 'sap-hana', key: 'sap-hana' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('sap-hana', 'sap-hana'),
  },

  // -- Shopfloor Guidance
  {
    path: 'v2-watchdog',
    component: ShellComponent,
    data: { appUrl: 'v2-watchdog', key: 'v2-watchdog' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('v2-watchdog', 'v2-watchdog'),
  },
  {
    path: 'task-service',
    component: ShellComponent,
    data: { appUrl: 'task-service', key: 'task-service' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('task-service', 'task-service'),
  },

  // -- Optimization
  {
    path: 'contiloop-ai',
    component: ShellComponent,
    data: { appUrl: 'contiloop-ai', key: 'contiloop-ai' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('contiloop-ai', 'contiloop-ai'),
  },

  // -- LineControl
  {
    path: 'lms',
    component: ShellComponent,
    data: { appUrl: 'lms', key: 'lms' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('lms', 'lms'),
  },

  // -- CoreService
  {
    path: 'user-management',
    component: ShellComponent,
    data: { appUrl: 'user-management', key: 'user-management' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('user-management', 'user-management'),
  },
  {
    path: 'equipment',
    component: ShellComponent,
    data: { appUrl: 'equipment', key: 'equipment' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('equipment', 'equipment'),
  },
  {
    path: 'external-api',
    component: ShellComponent,
    data: { appUrl: 'external-api', key: 'external-api' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('external-api', 'external-api'),
  },
  {
    path: 'shift-management',
    component: ShellComponent,
    data: { appUrl: 'shift-management', key: 'shift-management' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('shift-management', 'shift-management'),
  },
  {
    path: 'reason-code',
    component: ShellComponent,
    data: { appUrl: 'reason-code', key: 'reason-code' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('reason-code', 'reason-code'),
  },
  {
    path: 'connect',
    component: ShellComponent,
    data: { appUrl: 'connect', key: 'connect' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('connect', 'connect'),
  },

  // -- Legacy
  {
    path: 'demo-trigger',
    component: ShellComponent,
    data: { appUrl: 'demo-trigger', key: 'demo-trigger' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('demo-trigger', 'demo-trigger'),
  },
  {
    path: 'site-service',
    component: ShellComponent,
    data: { appUrl: 'site-service', key: 'site-service' },
    canActivate: [AuthorizeGuard],
    children: generateDefaultChild('site-service', 'site-service'),
  },

  // -- Error
  {
    path: 'forbidden',
    component: ShellComponent,
    data: { appUrl: 'errors/error403.html' },
  },
  {
    path: 'not-found',
    component: ShellComponent,
    data: { appUrl: 'errors/error404.html' },
  },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
